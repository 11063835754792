import { AccountType } from './enums/AccountType';
import { SportsType } from './enums/SportsType';

export const optimXTesting = false;
export const optimXTestWebsite = false;
export const optimXStoreWebsite = false;
export const websiteTeamId = 'dLlV95EnVgYyoBdittaQDWPhdgN2';
export const setWebsiteTeamId = (teamId: string) => {
    if (optimXTestWebsite || optimXStoreWebsite) {
        // eslint-disable-next-line no-console
        console.log(teamId);
    }
};
export const websiteTeamName = 'North Carolina Tar Heels';
export const websiteOrganizationId = 'l6d2lrno';
export const websiteOrganizationName = 'University of North Carolina ';
export const websiteAccountType = AccountType.TEAM;
export const websiteSportsType = SportsType.MENSRUGBY;
export const websiteSEOInfo = {
    title: 'UNC Rugby',
    description: "The Official Website for Carolina Men's Rugby. Follow us for all updates on your favorite UNC Sport team!",
    keywords: "UNC Rugby, University of North Carolina Rugby, North Carolina Tar Heels Rugby, University of North Carolina, North Carolina Tar Heels, Men's Rugby Team, UNC, Rugby, Tar Heels Rugby, NCR, National Collegiate Rugby, D1 AA rugby, NCR D1AA, Play Rugby UNC, Join UNC rugby, Top Rugby, Best rugby, best, rugby school, rugby college, rugby recruit, NCR nationals, rugby nationals, SRC, southern rugby conference, college for rugby, best college for rugby, best rugby program, north carolina rugby, southern rugby, join rugby, play rugby, national semi-finalist, Carolina athletics, UNC sports, Rugby in NC, rugby in carolina, rugby in north carolina, NC, best rugby in NC, where to play rugby in NC, top rugby colleges in NC, top rugby program in nation, top rugby in USA, club rugby, D1 rugby, D1, nationally ranked, nationals, best rugby in the nation, best rugby college in the nation, Joey Rasmus, Illinois Rugby ",
};
export const websiteLogo = {
    logo: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_1600x900.png?alt=media&token=c309156e-ccec-4653-ba80-a505432a1d14`,
    logo1: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_32_1600x900.png?alt=media&token=69f1e634-cbb8-46b2-adae-38c7b6eaf3bc`,
    logo2: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_196_1600x900.png?alt=media&token=9a23d8de-66ab-4c04-baff-3464009f27d3`,
    logo3: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_512_1600x900.png?alt=media&token=d32782b7-aa23-4be3-9590-c4cf84f41729`,
    logo4: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_1024_1600x900.png?alt=media&token=f6e1a802-0dd5-43db-9131-c8e03eacdae4`,
};
export const websiteSilhouetteLogo = {
    logo: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_silhouette_1600x900.png?alt=media&token=c2d77d94-0f3e-4690-8ae2-1798d928fae9`,
    logo1: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_silhouette_32_1600x900.png?alt=media&token=f43f2c4e-0b98-4ed7-8e12-9a2c4fedb016`,
    logo2: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_silhouette_196_1600x900.png?alt=media&token=ee39c6bc-e7fb-48ac-8ade-41d6d227faa5`,
    logo3: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_silhouette_512_1600x900.png?alt=media&token=df95ec4b-e94a-4ade-88b4-f5a915ad9984`,
    logo4: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2FteamLogo_silhouette_1024_1600x900.png?alt=media&token=ba0a75cd-3584-47af-b557-5d2e803950e2`,
};
export const websiteImages = [
    {
        id: 'featureImage1',
        imageTitle: 'Feature Image 1',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FfeatureImage1_1600x900.png?alt=media&token=21ec4941-877e-4c16-b04e-ad877f49e346`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'featureImage2',
        imageTitle: 'Feature Image 2',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FfeatureImage2_1600x900.png?alt=media&token=5dd51908-6c32-47ab-9ba8-d18aaaf4eb76`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'featureImage3',
        imageTitle: 'Feature Image 3',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FfeatureImage3_1600x900.png?alt=media&token=febb34de-32ab-45ea-b066-4170f9a29c45`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'mFeatureImage1',
        imageTitle: 'Mobile Feature Image 1',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FmFeatureImage1_1600x900.png?alt=media&token=3a66f3bd-225a-4358-8d1f-476b11d65970`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'mFeatureImage2',
        imageTitle: 'Mobile Feature Image 2',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FmFeatureImage2_1600x900.png?alt=media&token=543c8f23-966f-493c-baf9-d5a0fd3aadc0`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'mFeatureImage3',
        imageTitle: 'Mobile Feature Image 3',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FmFeatureImage3_1600x900.png?alt=media&token=758fa341-6f9d-4c44-a01c-daf2e029ec63`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'bannerImage1',
        imageTitle: 'Banner Image 1',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FbannerImage1_1600x900.png?alt=media&token=80c12fe9-5946-4382-99c7-45ed3f100ce8`,
        title: '',
        description: '',
        link: '',

    },
    {
        id: 'bannerImage2',
        imageTitle: 'Banner Image 2',
        image: '',
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'rosterFeatureImage',
        imageTitle: 'Default Roster Feature Image',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FrosterFeatureImage_1600x900.png?alt=media&token=0bee623b-84d6-4b3c-86fa-e4a3b1bf1955`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'articleFeatureImage',
        imageTitle: 'Default Article Feature Image',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FarticleFeatureImage_1600x900.png?alt=media&token=e531aa52-09f1-438f-92d8-d94a33b0cc8d`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'gameFeatureImage',
        imageTitle: 'Default Game Feature Image',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FgameFeatureImage_1600x900.png?alt=media&token=a0968cfe-d2b2-4ee3-98b8-103b254db052`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'galleryFeatureImage',
        imageTitle: 'Default Gallery Feature Image',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FgalleryFeatureImage_1600x900.png?alt=media&token=0530267d-217c-4b84-bae4-f51727fee04a`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'eventFeatureImage',
        imageTitle: 'Default Event Feature Image',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FeventFeatureImage_1600x900.png?alt=media&token=0530267d-217c-4b84-bae4-f51727fee04a`,
        title: '',
        description: '',
        link: '',
    },
    {
        id: 'practiceFeatureImage',
        imageTitle: 'Default Practice Feature Image',
        image: `https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/teams%2F${websiteTeamId}%2Fimages%2FpracticeFeatureImage_1600x900.png?alt=media&token=0530267d-217c-4b84-bae4-f51727fee04a`,
        title: '',
        description: '',
        link: '',
    },
];
export const websiteStyles = {
    design: {
        homepage: 1,
        about: 0,
        sponsors: 0,
        forms: 0,
        article: 0,
        articles: 0,
        game: 0,
        games: 0,
        gallery: 0,
        galleries: 0,
        player: 0,
        players: 0,
        coach: 0,
        coaches: 0,
        staff: 0,
        staffs: 0,
        events: 0,
        event: 0,
    },
    colors: {
        primaryColor: '#16284b',
        secondaryColor: '#7bafd4',
        fontColor: '#ffffff',
    },
    navLinks: [],
    darkMode: 0,
    bannerText: 'We will welcome you soon to our stadium',
};

export const reCaptchaSiteKey = '6LfZckQbAAAAAM2YfByhm4h6uSw9ySQKWhc_lgM-';
